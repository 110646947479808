function addQueryParams (params) {
  const url = new URL(window.location.href)

  Object.keys(params).forEach(key => {
    url.searchParams.set(key, params[key])
  })
  window.history.replaceState(null, '', url.toString())
}

function clearQueryParams () {
  window.history.replaceState(null, '', window.location.pathname)
}

export {
  addQueryParams,
  clearQueryParams
}