import { h, render } from 'vue'
import { uid } from 'radash'

function timeout (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Usage

// import ModalWindow
// import ComponentContent
// modalWindowProps = { wrapperClass: 'max-w-2xl overflow-y-auto rounded-xl bg-white p-4 shadow-lg' }
// componentContent = { heading: 'hello world' }
// createComponent(ModalWindow, modalWindowProps, ComponentContent, componentContent)

function createComponent (wrapperComponent, wrapperProps = {}, componentContent = {}, componentProps = {}, id = null) {
  let el = document.createElement('div')
  document.body.appendChild(el)

  el.id = id ? id : `${uid(6)}-modal`

  let vNode = null

  function destroy () {
    render(null, el)
    vNode = null
    el.remove()
    el = null
  }

  componentProps = { ...componentProps, onDestroy: destroy }
  componentContent = h(componentContent, componentProps)

  wrapperProps = { ...wrapperProps, onDestroy: destroy }
  vNode = h(wrapperComponent, wrapperProps, () => componentContent)

  render(vNode, el)
  return { vNode, destroy }
}

function redirectToNewDomain (newDomain = 'https://one2fan.com', additionalParams = {}) {
  // const newDomain = 'https://newdomain.com'

  // const additionalParams = {
  //   newParam1: 'value1',
  //   newParam2: 'value2'
  // }

  const currentUrl = window.location.href

  const url = new URL(currentUrl)
  const newDomainUrl = new URL(newDomain)

  url.protocol = newDomainUrl.protocol
  url.host = newDomainUrl.host
  url.port = ''

  for (const [key, value] of Object.entries(additionalParams)) {
    url.searchParams.set(key, value) // Adds or updates the parameter
  }

  window.location.href = url.toString()
}

export {
  timeout,
  createComponent,
  redirectToNewDomain
}
