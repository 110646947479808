<template>
  <Header :name="$t('user.credits.sub_heading')"/>

  <CreditsPlaceholder v-if="isLoading"/>

  <div
    class="container my-5">
    <h1 class="mb-3" v-html="$t('user.credits.heading')"/>

    <p class="mb-5">
      {{ $t("user.credits.description2") }}
    </p>

    <CreditsPlans/>

    <LegalInformation/>
  </div>
</template>

<script>
import Header from '@/components/Functional/Header'
import { mapState } from 'pinia'
import { usePaymentStore } from '@/stores/payment'
import LegalInformation from '@/components/Credits/LegalInformation'
import CreditsPlaceholder from '@/components/Credits/CreditsPlaceholder'
import { useAppStore } from '@/stores/app'
import CreditsPlans from '@/components/Credits/CreditsPlans.vue'
import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

export default {
  name: 'Credits',
  components: {
    Header,
    LegalInformation,
    CreditsPlaceholder,
    CreditsPlans
  },
  data () {
    return {
      constantLoading: true
    }
  },
  computed: {
    ...mapState(useAppStore, [
      'isiOS'
    ]),
    ...mapState(usePaymentStore, [
      'products'
    ]),
    isLoading () {
      return !this.products.length || this.constantLoading
    }
  },
  beforeCreate () {
    document.title = 'Select Tariff'
  },
  created () {
    setTimeout(() => {
      this.constantLoading = false
    }, 500)
  },
  beforeMount () {},
  mounted () {
    if (this.isiOS) {
      document.body.scrollTop = 0
    } else {
      document.documentElement.scrollTop = 0
    }

    clearQueriesAndAddWebviewParams()
  },
  methods: {}
}
</script>
