import { defineStore } from 'pinia'
import app from '@/main'
import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { useAppStore } from '@/stores/app'
import { webviewType } from '@/helpers/is-webview'

export const useQuizStore = defineStore({
  id: 'quiz',
  state: () => ({
    survey: {}, // State of the survey QUIZ from API session.start -> options. `null` if it is not available
    surveyOpen: false, // Current state visibility of quiz
    surveyWasOpened: false, // Quiz opened once?
    answers: [],
    // Where from quiz was fired?
    // https://github.com/wiggumlab/fansy-server/blob/8b5e1b5c5649f8df6de81ce8f8aa5f1a1548c602/doc/API/methods/survey.shown.md#surveyshown
    surveyCause: '',
    quizReset: false
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'surveyWasOpened'
        ]
      }
    ]
  },
  getters: {
    quizAllowed: (state) => {
      const userStore = useUserStore()
      const authStore = useAuthStore()
      const appStore = useAppStore()

      const condition1 = state.survey && state.survey?.canBeShown && userStore.user?.landingPath.includes('@')
      const condition2 = state.survey && state.survey?.canBeShown && state.survey?.lastPaidSubscriptionCreationPublisherUserId

      return ((condition1 || condition2) && !state.surveyWasOpened && authStore.isAuthenticated && !appStore.secretToken) || state.quizReset
    }
  },
  actions: {
    surveyQuestionAnswered ({ questionNumber, answerNumber }) {
      app.wsp.sendRequest({
        data: {
          questionNumber: questionNumber,
          answerNumber: answerNumber,
          browserWebViewApp: webviewType()
        },
        method: 'survey.questionAnswered'
      })
    },
    async surveyShown () {
      await app.wsp.sendRequest({
        data: {
          cause: this.surveyCause || 'show_survey',
          browserWebViewApp: webviewType()
        },
        method: 'survey.shown'
      })
    },
    // Events
    sessionStart (data) {
      if (data?.data.options?.survey) {
        this.survey = data.data.options.survey
      }
    },
    surveyChanged (data) {
      if (data?.data?.survey) {
        this.survey = data.data.survey
      }
    },
    async surveyShowButtonShown () {
      await app.wsp.sendRequest({
        method: 'survey.showButtonShown'
      })
    }
  }
})
