<template>
  <div
    :class="[noItems ? 'full-height-hack overflow-hidden flex flex-col px-4' : 'px-2 h-full',]"
    class="max-w-md mx-auto pt-6 pb-2">
    <div class="max-w-md mx-auto px-2 py-1.5 fixed z-30 top-0 left-0 right-0 bg-white">
      <router-link
        :to="`/chat/${$route.params.chatId}`"
        class="flex items-center gap-0.5 text-sm text-gray-500 hover:text-link">
        <InlineSvg
          class="w-3 h-3"
          :src="require('@/assets/svg/design/arrow-left.svg')"/>

        <span>{{ $t('nav.chat.back_to_chat') }}</span>
      </router-link>
    </div>

    <h1 class="mt-4 mb-6">
      {{ $t('chat.attachments.dialogue_attachments') }}
    </h1>

    <template v-if="isInitialLoading">
      <div
        v-show="isInitialLoading"
        class="flex items-center justify-center">
        <span class="loader w-16 h-16 my-12"/>
      </div>
    </template>

    <template v-else>
      <ChatAttachmentsNoItems
        v-if="noItems"
        class="flex-grow"/>

      <div v-else-if="errorMessage">
        <p>{{ errorMessage }}</p>
      </div>

      <ul
        v-else
        class="grid gap-0.5 grid-cols-3">
        <ChatAttachmentsItem
          v-for="(item, index) in items"
          :key="item.messageId"
          :item="item"
          @click="$refs.modalAttachments.openModal({ initialIndex: index })"/>

        <Observer @intersect="intersected('load_more')"/>
      </ul>

      <div
        v-show="loadingStatus === 'loading-more'"
        class="flex items-center justify-center py-2">
        <span class="!border-2 w-4 h-4 loader"/>
      </div>

      <p
        v-show="loadingStatus === 'loaded-all' && items.length"
        class="px-4 text-center py-2">
        {{ $t('common.loaded_all') }}
      </p>
    </template>

    <Modal
      ref="modalAttachments"
      v-slot="{ values, closeModal }"
      name="Attachments"
      :is-rounded="false"
      :has-indents="false"
      :is-full-height="true"
      :allow-close-by-overlay="true"
      :show-desktop-close-cross="true"
      content-class="h-full bg-gray-700"
      position="center">
      <ChatAttachmentsSwiper
        :values="values"
        :items="items"
        @send-attachment="$refs.sendPhotoModal.openModal($event)"
        @unlock-attachment="$refs.unlockPhotoContent.openModal($event)"
        @slide-changed="slideChanged"
        @reach-end="intersected"
        @close="closeModal"/>
    </Modal>

    <!-- Unblock outgoin attachment from logged user -->
    <Modal
      v-slot="{ values, closeModal }"
      ref="sendPhotoModal"
      name="SendPhoto">
      <SendPhotoContent
        :values="values"
        @unlocked="unlockedAttachment"
        @close="closeModal"/>
    </Modal>

    <!-- Unblock incoming attachment from creator -->
    <Modal
      v-slot="{ values, closeModal }"
      ref="unlockPhotoContent"
      name="UnlockAttachment">
      <UnlockAttachment
        :values="values"
        @unlocked="unlockedAttachment"
        @close="closeModal"/>
    </Modal>
  </div>
</template>

<script>
import Observer from '@/components/Functional/Observer.vue'
import ChatAttachmentsNoItems from '@/components/ChatAttachments/ChatAttachmentsNoItems.vue'
import ChatAttachmentsItem from '@/components/ChatAttachments/ChatAttachmentsItem.vue'
import ChatAttachmentsSwiper from '@/components/ChatAttachments/ChatAttachmentsSwiper.vue'
import SendPhotoContent from '@/components/ModalContents/chat/SendPhotoContent.vue'
import UnlockAttachment from '@/components/ModalContents/chat/UnlockAttachment.vue'
import { useChatStore } from '@/stores/chat'
import { mapActions, mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

export default {
  name: 'ChatAttachments',
  components: {
    Observer,
    ChatAttachmentsNoItems,
    ChatAttachmentsItem,
    ChatAttachmentsSwiper,
    SendPhotoContent,
    UnlockAttachment
  },
  data () {
    return {
      constantLoading: true,
      loadingStatus: '',
      errorMessage: '',
      items: [],
      limit: 24
    }
  },
  computed: {
    ltId () {
      return this.items.length > 0 ? this.items[this.items.length - 1].messageId.toString() : null
    },
    noItems () {
      return !this.items.length && this.loadingStatus.includes('loaded')
    },
    isInitialLoading () {
      return this.loadingStatus === 'initial-loading' || this.constantLoading
    },
    isLoading () {
      return this.constantLoading || this.loadingStatus.includes('loading')
    },
    ...mapState(useAuthStore, [
      'options'
    ])
  },
  async beforeCreate () {
    const chatStore = useChatStore()

    chatStore.chatId = this.$route.params.chatId

    await this.userGet()
  },
  mounted () {
    setTimeout(() => {
      this.constantLoading = false
    }, 500)

    this.getDialogueAttachemtns('initial_load')

    clearQueriesAndAddWebviewParams()
  },
  methods: {
    ...mapActions(useChatStore, [
      'userGet'
    ]),
    unlockedAttachment (message) {
      const index = this.items.findIndex((item) => item.messageId === message.messageId)

      if (index !== -1) {
        this.items.splice(index, 1, message)
      }
    },
    async getDialogueAttachemtns (type) {
      if (type === 'load_more') {
        this.loadingStatus = 'loading-more'
      }
      else if (type === 'initial_load') {
        this.loadingStatus = 'initial-loading'
      }
      else {
        this.loadingStatus = 'loading'
      }

      try {
        const res = await this.wsp.sendRequest({
          data: {
            ltId: this.ltId,
            limit: this.limit,
            onlyWithAttachments: true,
            userId: this.$route.params.chatId
          },
          method: 'messages.get'
        })

        if (res.error) {
          return Promise.reject(res.error)
        }

        const messages = res.data.messages

        if (messages) {
          if (type === 'load_more') {
            this.items.push(...messages)
          }
          else {
            this.items = messages
          }
        }

        // console.log(messages.length < this.limit, messages.length, this.limit)

        if (messages.length === 0) {
          this.loadingStatus = 'loaded-all'
        }
        else {
          this.loadingStatus = 'loaded'
        }
      } catch (error) {
        this.errorMessage = error
        this.loadingStatus = 'loaded'
      }
    },
    intersected () {
      if (this.isLoading || this.loadingStatus === 'loaded-all') {
        return
      }

      this.getDialogueAttachemtns('load_more')
    },
    slideChanged ({ index }) {
      // Upload more posts before slider reaches the end
      if (this.items.length - index <= 5) {
        this.intersected()
      }
    }
  }
}
</script>

