<template>
  <div class="container pt-5 pb-20 min-h-screen">
    <DialogsPlaceholder v-show="isLoading"/>

    <div v-show="!isLoading">
      <section
        v-show="invites.length > 0 || (fakeInvite?.name && !fakeInvite.contact)"
        class="mb-6">
        <div class="flex items-center justify-between gap-2">
          <h2>
            {{ $t('user.messages.recommended') }}
          </h2>

          <AppBalance/>
        </div>
    
        <TheInvites class="h-30"/>
      </section>

      <div class="flex items-center justify-between gap-2 mb-2">
        <h2>
          {{ $t('user.messages.heading') }}
        </h2>

        <AppBalance v-if="!(invites.length > 0 || (fakeInvite?.name && !fakeInvite.contact))"/>
      </div>

      <TheContacts class="flex-grow-1"/>
    </div>

    <Navbar/>
  </div>
</template>

<script>
import TheContacts from '@/components/Dialogs/TheContacts'
import TheInvites from '@/components/Invites/TheInvites'
import { useMeta } from 'vue-meta'
import { mapActions, mapState } from 'pinia'
import { useDialogsStore } from '@/stores/dialogs'
import DialogsPlaceholder from '@/components/Dialogs/DialogsPlaceholder'
import Navbar from '@/components/Navbar'
import AppBalance from '@/components/App/AppBalance.vue'
import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

export default {
  name: 'Messages',
  components: {
    Navbar,
    TheContacts,
    TheInvites,
    DialogsPlaceholder,
    AppBalance
  },
  setup () {
    useMeta({
      title: 'Messages',
      og: {
        title: 'Discover',
        description: 'One2fan – get closer to the creators',
        site_name: 'Fancy',
        type: 'website'
      }
    })
  },
  data () {
    return {
      constantLoading: true
    }
  },
  computed: {
    ...mapState(useDialogsStore, [
      'invites',
      'fakeInvite'
    ]),
    ...mapState(useDialogsStore, [
      'contactsStatus'
    ]),
    ...mapState(useDialogsStore, [
      'invitesStatus'
    ]),
    isLoading () {
      return this.constantLoading ||
        this.contactsStatus === 'loading' ||
        this.invitesStatus === 'loading'
    }
  },
  beforeMount () {
    this.resetState()

    setTimeout(() => {
      this.constantLoading = false
    }, 500)
  },
  beforeCreate () {
    document.title = 'Messages'
  },
  mounted () {
    clearQueriesAndAddWebviewParams()
  },
  methods: {
    ...mapActions(useDialogsStore, [
      'dialogGetList',
      'resetState'
    ])
  }
}
</script>
