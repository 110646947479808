<template>
  <router-link
    v-if="path"
    class="py-1 text-decoration-none d-flex align-items-center btn pl-0"
    :to="path">
    <svg
      width="13"
      height="13"
      viewBox="0 0 12 21"
      class="d-inline-block"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.037 20.583L.792 11.446a.975.975 0 010-1.392L10.037.917a1.456 1.456 0 012.04 0c.563.557.563 1.46 0 2.016L4.17 10.751l7.909 7.815c.562.557.562 1.46 0 2.017a1.456 1.456 0 01-2.04 0z"/>
    </svg>
  </router-link>

  <button
    v-else
    class="py-1 text-decoration-none d-flex align-items-center btn pl-0"
    @click="goBack">
    <svg
      width="13"
      height="13"
      viewBox="0 0 12 21"
      class="d-inline-block"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.037 20.583L.792 11.446a.975.975 0 010-1.392L10.037.917a1.456 1.456 0 012.04 0c.563.557.563 1.46 0 2.016L4.17 10.751l7.909 7.815c.562.557.562 1.46 0 2.017a1.456 1.456 0 01-2.04 0z"/>
    </svg>
  </button>
</template>

<script>
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'

export default {
  name: 'LinkBack',
  components: {},
  props: {
    path: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useAuthStore, [
      'isUserFeedEnabled'
    ])
  },
  mounted () {
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>
