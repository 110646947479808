// import { useAppStore } from '@/stores/app.js'

function isWebView () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  const isAndroidWebView = /; wv\)/.test(userAgent) || /Android.*Version\/[\d.]+/.test(userAgent)
  const isIOSWebView = (/iPhone|iPod|iPad/.test(userAgent)) && /AppleWebKit/.test(userAgent) && !/Safari/.test(userAgent)
  const lacksIndexedDB = !window.indexedDB
  const lacksStandAlone = !window.navigator.standalone
  const isNotPWA = !window.matchMedia('(display-mode: standalone)').matches

  return (isAndroidWebView || isIOSWebView || (lacksIndexedDB && lacksStandAlone && isNotPWA))
}

function isTelegramWebView () {
  if (typeof window.Telegram !== 'undefined' && typeof window.Telegram.WebApp !== 'undefined') {
    return true
  }

  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  return /Telegram/i.test(userAgent)
}

function isTikTokWebView () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  const tiktokIdentifiers = [
    /TikTok/i,
    /Musical_ly/i,
    /com\.ss\.android\.ugc\.aweme/i,
    /Douyin/i,
    /BytedanceWebview/i,
    /ByteLocale/i,
    /JsSdk\//i
  ]

  for (let i = 0; i < tiktokIdentifiers.length; i++) {
    if (tiktokIdentifiers[i].test(userAgent)) {
      return true
    }
  }

  return false
}

function isInstagramWebView () {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera

  const instagramIdentifiers = [
    /Instagram/i,
    /IAB/i,        // In-App Browser
    /IABMV/i,      // In-App Browser Mobile View
    /FB_IAB/i,     // Facebook In-App Browser, sometimes shared
    /FBAV/i        // Facebook App Version, for broader detection if needed
  ]

  for (let i = 0; i < instagramIdentifiers.length; i++) {
    if (instagramIdentifiers[i].test(userAgent)) {
      return true
    }
  }

  return false
}

function isWebViewFullCheck () {
  return isWebView() || isTelegramWebView() || isTikTokWebView() || isInstagramWebView()
}

function webviewType () {
    if (isTelegramWebView()) {
        return 'telegram'
    } else if (isTikTokWebView()) {
        return 'tiktok'
    } else if (isInstagramWebView()) {
        return 'instagram'
    } else if (isWebView()) {
        return 'other'
    }

    return 'none'
}


export {
  isWebView,
  isTelegramWebView,
  isTikTokWebView,
  isInstagramWebView,
  isWebViewFullCheck,
  webviewType
}