import { clearQueryParams, addQueryParams } from '@/helpers/helpers.js'
import { useAppStore } from '@/stores/app.js'
import { useAuthStore } from '@/stores/auth.js'
import { isWebViewFullCheck } from '@/helpers/is-webview.js'

function addWebviewParams () {
  const appStore = useAppStore()
  const authStore = useAuthStore()

  if ((isWebViewFullCheck() || appStore.webViewSimulate) && authStore.webViewEscape?.signinToken.value) {
    const webviewParams = {
      from_webview: 1,
      signin_token: authStore.webViewEscape.signinToken.value
    }

    if (authStore.webViewEscape?.redirectToCanonicalClientOrigin) {
      webviewParams.redirect_to_canonical_host = 1
    }

    if (authStore.canonicalHostname) {
      webviewParams.hostname = authStore.canonicalHostname
    }

    addQueryParams(webviewParams)
  }
}

function clearQueriesAndAddWebviewParams () {
  clearQueryParams()
  addWebviewParams()
}

export {
  clearQueriesAndAddWebviewParams,
  addWebviewParams
}