import app from '@/main'
import { defineStore } from 'pinia'
import { set, pickBy, identity } from 'lodash'
import { useUserStore } from './user'
import { useAppStore } from './app'
import helpers from '@/helpers'
import uniqid from 'uniqid'
import { notify } from '@kyvg/vue3-notification'
import i18nHelpers from '@/i18nHelpers'
import { usePaymentStore } from '@/stores/payment'
import { useQuizStore } from '@/stores/quiz'
import { useEventsStore } from '@/components/Events/Store/events'
import { webviewType, isWebViewFullCheck } from '@/helpers/is-webview.js'
import ModalWindow from '@/components/Functional/ModalWindow.vue'
import OpenInBrowserModal from '@/components/ModalsNext/OpenInBrowserModal.vue'
import { createComponent } from '@/helpers/utils.js'
import { clearQueriesAndAddWebviewParams, addWebviewParams } from '@/helpers/app-helpers.js'
import { clearQueryParams } from '@/helpers/helpers.js'

function openInBrowserModal () {
  const modalProps = {
    wrapperClass: 'max-w-md mx-auto w-full h-full flex-col'
  }

  const OpenInBrowserModalProps = {}

  createComponent(ModalWindow, modalProps, OpenInBrowserModal, OpenInBrowserModalProps)
}

// MessageMeClick
// https://admin.1.ts.fansy.lol/features/MessageMeClick
// RecommendedDialogClick
// https://admin.1.ts.fansy.lol/features/RecommendedDialogClick
// RecommendedDialogNotification
// https://admin.1.ts.fansy.lol/features/ChatRequiresSubscription

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    loginOptions: {
      platform: 'st',
      appVersion: '1.0.0',
      buildType: 'release',
      deviceFormat: 'web',
      deviceId: 'web',
      deviceOsVersion: 'Web',
      deviceType: 'web',
      systemLocale: 'en-US',
      clientLocale: 'en-US',
      attachmentSupport: 'photo',
      appId: process.env.VUE_APP_ID
    },
    registrationOptions: {
      appVersion: '1.0.0',
      buildType: 'release',
      deviceFormat: 'web',
      deviceId: 'web',
      deviceOsVersion: 'Web',
      deviceType: 'web',
      systemLocale: 'en-US',
      clientLocale: 'en-US',
      platform: 'st',
      timeZone: 'Europe/Moscow',
      attachmentSupport: 'photo',
      appId: process.env.VUE_APP_ID
    },
    options: {
      canonicalClientOrigin: null,
      multipleProfilePhotosEnabled: null,
      // list of active creator categories (hot/top/new/all) if available.
      activeCreatorCategories: null,
      trialCreditsForBankCard: {
        creditCount: 0,
        credited: false
      },
      userFeedEnabled: null,
      verificationEnabled: null,
      chatNavigationEnabled: null,
      chatCreditBalanceEnabled: null, // Отображать ли в чате баланс кредитов
      creditsPopupChatPriceEnabled: null, // Отображать ли стоимость чата в попапе покупки
      // survey options - in quiz.js
      chatRequiresSubscription: true,
      profileCompletenessEnabled: null,
      clickDestinations: {
        recommendedDialogNotification: null,
        recommendedDialogListItem: null
      },
      eventsEnabled: false,
      webViewEscape: {
        redirectToCanonicalClientOrigin: null,
        signinToken: {
          value: null,
          nextRefreshTs: null
        }
      }
    },
    beforeAuthOptions: {
      onboardingEnabled: null,
      userFeedEnabled: null
    },
    token: JSON.parse(localStorage.getItem('token')) || '',
    isConnected: false,
    isOnline: true,
    tryingToReconnect: false,
    reconectionAttempts: 0,
    userIsInactive: false,
    isAuthenticated: false,
    loginIsVisible: false,
    signupIsVisible: false,
    onboardingIsVisible: false,
    recoverEmail: '',
    showGiftPopup: false,
    trackingQuery: {
      // trafficSourceId
      // trafficSourceName
    },
    // First page user visited before registration
    landingPath: {
      name: '',
      path: ''
    },
    // Path to redirect after registration
    pathToRedirect: '',
    showSwiperPreview: true,
    isAddToHomeAllowed: true,
    // For future redirect from mirrors to main domain. Fills only if there are query params
    hrefOrigin: '',
    // Tracking ID (before user sign up)
    installId: null,
    // Authorization by temporary link from the letter: https://wiggum.atlassian.net/browse/FAN-650
    signinToken: null,
    openInBrowserModalWasOpened: false,
    reloadTimestamp: null
  }),
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: [
          'token',
          'pathToRedirect',
          'trackingQuery',
          'landingPath',
          'showSwiperPreview',
          'isAddToHomeAllowed',
          'hrefOrigin',
          'installId',
          'reloadTimestamp'
        ]
      },
      {
        storage: sessionStorage,
        paths: [
          'shouldShowOpenInBrowserModal'
        ]
      }
    ]
  },
  getters: {
    canonicalHostname: (state) => state.options?.canonicalClientOrigin ? state.options?.canonicalClientOrigin.replace(/^https?:\/\//, '') : null,
    isTokenExist: (state) => !!(state.token && state.token.length > 0),
    trialCreditsForBankCard: (state) => state.options.trialCreditsForBankCard,
    isUserFeedEnabled (state) {
      return state?.beforeAuthOptions?.userFeedEnabled || state.options.userFeedEnabled
    },
    authOptions (state) {
      return this.isTokenExist ? state.options : state.beforeAuthOptions
    },
    webViewEscape (state) {
      if (state.options.webViewEscape) {
        return state.options.webViewEscape
      }
      else {
        return {
          redirectToCanonicalClientOrigin: null,
          signinToken: {
            value: null,
            nextRefreshTs: null
          }
        }
      }
    }
  },
  actions: {
    setToken (options) {
      let token = ''

      if (options !== undefined) {
        token = options.authorization.split(' ')[1]
      }

      this.token = token
    },
    // При загрузке клиента, если пользователь не залогинен, и отсутствует сохраненный локально Install ID,
    // сделать запрос install.create, и сохранить полученный Install ID в постоянном локальном хранилище.
    // При вызове метода registration.create, если есть сохраненный локально Install ID, передавать его в параметре installId.
    async installCreate () {
      let options = {}

      const signUpTrackingParams = await helpers.signUpTrackingParams()
      const browserLocale = i18nHelpers.getBrowserLocale({ countryCodeOnly: true })
      const appStore = useAppStore()

      set(options, 'systemLocale', browserLocale)
      set(options, 'clientLocale', appStore.selectedLocale || browserLocale)

      if (this.landingPath.path) {
        options.landingPath = this.landingPath.path.substring(1)
      }

      options = {
        ...options,
        ...signUpTrackingParams,
        ...this.trackingQuery
      }

      try {
        const response = await app.wsp.sendRequest({
          data: pickBy(options, identity),
          method: 'install.create'
        })

        if (response.error && response.error?.message) {
          console.log(response.error.message)
          return
        }

        if (response.data?.id) {
          this.installId = response.data.id
        }
      } catch (e) {
        console.log(e)
      }
    },
    // Для получения состояния онбординга сделать запрос session.get

    // Should be called only when the current user is not authenticated.
    // When authenticated, use session.start instead.
    async sessionGet () {
      const appStore = useAppStore()

      if (this.installId) {
        // https://github.com/wiggumlab/fansy-server/blob/e70b95455f6e6bb5cf80bf89464ca8c5dfaa3e92/doc/API/methods/session.get.md
        const response = await app.wsp.sendRequest({
          data: {
            installId: this.installId,
            postUnlockToken: appStore.secretToken
          },
          method: 'session.get'
        })

        if (response.data?.options) {
          this.beforeAuthOptions = response.data.options
        }

        if (!response.data?.options.postUnlockTokenIsValid) {
          appStore.secretToken = null
        }
      }
    },
    async startSession ({
      email = '', password = '', token = '', signinToken = '', shouldRedirect = false, userCameFromWebview = false
    }) {
      let loginOptions = this.loginOptions

      if (email && password) {
        email && set(loginOptions, 'email', email)
        password && set(loginOptions, 'password', password)
      }

      const appStore = useAppStore()

      const browserLocale = i18nHelpers.getBrowserLocale({ countryCodeOnly: true })

      set(loginOptions, 'systemLocale', browserLocale)
      set(loginOptions, 'clientLocale', appStore.selectedLocale || browserLocale)
      set(loginOptions, 'postUnlockToken', appStore.secretToken)
      set(loginOptions, 'clientHost', window.location.hostname)

      if (appStore.webViewSimulate) {
        set(loginOptions, 'browserWebViewApp', 'tiktok')
      }
      else {
        set(loginOptions, 'browserWebViewApp', webviewType())
      }

      if (userCameFromWebview) {
        set(loginOptions, 'webViewEscape', userCameFromWebview)
      }

      if (token) {
        loginOptions.authorization = `Bearer ${token || this.token}`
      }

      if (signinToken) {
        loginOptions.signinToken = signinToken
        this.signinToken = null
      }

      const signUpTrackingParams = await helpers.signUpTrackingParams()

      loginOptions = {
        ...loginOptions,
        ...signUpTrackingParams,
        ...this.trackingQuery
      }

      if ((email && password) || token || signinToken) {
        try {
          const response = await app.wsp.sendRequest({
            data: loginOptions,
            method: 'session.start'
          })

          if (response.error) {
            if (response.error.message === 'invalid token') {
              this.$reset()
              const userStore = useUserStore()
              userStore.$reset()
              localStorage.clear()

              window.location = window.location.pathname
            }
            else if (token || signinToken) {
              await this.router.push('/service-unavailable')
              return
            }

            const error = response.error

            throw new Error(error?.message ? error.message : 'Unknown error', {
              cause: error
            })
          }

          this.isAuthenticated = true
          this.trackingQuery = {}
          this.options = response.data.options

          if (!response.data?.options.postUnlockTokenIsValid) {
            appStore.secretToken = null
          }

          this.setToken(this.options)

          app.$addEvent(['Session start'])

          // Hack to getting events badge in the NavBar
          if (this.options?.eventsEnabled) {
            try {
              const eventsStore = useEventsStore()
              const { data } = await eventsStore.dialogGetList(null, 1)
              eventsStore.thereAreEvents = data?.items?.length > 0
            } catch (e) {
              console.log(e)
            }
          }

          if (shouldRedirect && this.pathToRedirect) {
            await this.router.push(this.pathToRedirect)
            this.pathToRedirect = ''
          }
          else {
            const currentUrl = window.location.href

            if (currentUrl.includes('service-unavailable')) {
              await this.router.push('/')
            }
          }

          if ((isWebViewFullCheck() || appStore.webViewSimulate) && !this.openInBrowserModalWasOpened && this.options.webViewEscape?.signinToken.value) {
            this.openInBrowserModalWasOpened = true
            setTimeout(() => openInBrowserModal(), 20000)
          }

          addWebviewParams()

          const wvType = webviewType()

          if ((wvType === 'tiktok' || appStore.webViewSimulate) && appStore.isiOS && this.options.webViewEscape?.signinToken.value) {
            const currentTimestamp = Date.now()

            // 120000 is 2 minutes = 120,000 milliseconds
            if (this.reloadTimestamp && ((currentTimestamp - this.reloadTimestamp) < 120000)) {
              console.log('Less than 5 minutes since last reload. No action needed.')
            } else {
              console.log('5 minutes have passed. Reloading the page!')
              this.reloadTimestamp = currentTimestamp

              window.location.reload()
            }
          }

          return true
        } catch (error) {
          throw new Error(error?.message ? error.message : 'Unknown error', {
            cause: error
          })
        }
      }
    },
    async signUp (values) {
      const appStore = useAppStore()
      let registrationOptions = this.registrationOptions

      set(registrationOptions, 'prelandingId', values.prelandingId)
      set(registrationOptions, 'sex', values.sex)
      set(registrationOptions, 'name', values.name)
      set(registrationOptions, 'username', values.username)
      set(registrationOptions, 'email', values.email)
      set(registrationOptions, 'age', values.age)
      set(registrationOptions, 'password', values.password)
      set(registrationOptions, 'postUnlockToken', appStore.secretToken)
      set(registrationOptions, 'clientHost', window.location.hostname)

      if (appStore.webViewSimulate) {
        set(registrationOptions, 'browserWebViewApp', 'tiktok')
      }
      else {
        set(registrationOptions, 'browserWebViewApp', webviewType())
      }

      const browserLocale = i18nHelpers.getBrowserLocale({ countryCodeOnly: true })

      set(registrationOptions, 'systemLocale', browserLocale)
      set(registrationOptions, 'clientLocale', appStore.selectedLocale || browserLocale)

      const signUpTrackingParams = await helpers.signUpTrackingParams()

      registrationOptions = {
        ...registrationOptions,
        ...signUpTrackingParams,
        ...this.trackingQuery
      }

      if (this.installId) {
        registrationOptions.installId = this.installId
      }

      if (this.landingPath.path) {
        registrationOptions.landingPath = this.landingPath.path.substring(1)
      }

      try {
        const response = await app.wsp.sendRequest({
          data: registrationOptions,
          method: 'registration.create'
        })

        this.isAuthenticated = true

        if (response.error) {
          const error = response.error

          throw new Error(error?.message ? error.message : 'Unknown error', {
            cause: error
          })
        }

        // Fancy register (propellerads)
        helpers.addScript('https://my.rtmark.net/p.js?f=sync&lr=1&partner=012079484f9b4e14d809a1561a17bf0c230e289d6e3f0a5f4047af4d6a303ce4')

        this.trackingQuery = {}

        // Add Twitter event for signup
        helpers.addTextScript('twq(\'event\', \'tw-of10d-of19n\', {});')

        notify({
          id: uniqid(),
          group: 'success',
          title: app.$i18n.t('common.success'),
          text: app.$i18n.t('notifications.registration_successful')
        })

        this.options = response.data.options
        this.setToken(response.data.options)

        if (response.data.options === undefined) return

        if (response.data.options.trialCreditsForBankCard?.creditCount) {
          this.showGiftPopup = true
        }

        if (!response.data?.options.postUnlockTokenIsValid) {
          appStore.secretToken = null
        }

        const userStore = useUserStore()
        const paymentStore = usePaymentStore()
        const quizStore = useQuizStore()

        paymentStore.sessionStart(response)
        userStore.sessionStart(response)
        quizStore.sessionStart(response)

        if ((isWebViewFullCheck() || appStore.webViewSimulate) && !this.openInBrowserModalWasOpened && this.options.webViewEscape?.signinToken.value) {
          this.openInBrowserModalWasOpened = true
          setTimeout(() => openInBrowserModal(), 20000)
        }

        clearQueriesAndAddWebviewParams()

        app.$addEvent(['Session start'])

        const wvType = webviewType()

        if ((wvType === 'tiktok' || appStore.webViewSimulate) && appStore.isiOS && this.options.webViewEscape?.signinToken.value) {
          this.reloadTimestamp = Date.now()
          window.location.reload()
        }
      } catch (error) {
        throw new Error(error?.message ? error.message : 'Unknown error', {
          cause: error
        })
      }
    },
    async sessionSuspend () {
      await this.clearState()
    },
    async meRecoverPassword (data) {
      const response = await app.wsp.sendRequest({
        data: data,
        method: 'me.recoverPassword'
      })

      if (response.error) return

      this.recoverEmail = data.email
    },
    clearState () {
      const packageVersion = localStorage.getItem('packageVersion')
      this.token = ''
      this.$reset()

      const userStore = useUserStore()
      const authStore = useAuthStore()
      const appStore = useAppStore()

      userStore.$reset()
      authStore.$reset()
      appStore.$reset()

      localStorage.clear()
      localStorage.setItem('packageVersion', packageVersion)

      window.location.reload(true)
    },
    async logOut () {
      await app.wsp.sendRequest({ method: 'session.destroy' })
        .finally(async () => {
          app.$addEvent(['Log out'])

          clearQueryParams()

          this.clearState()
        })
    }
  }
})
