<template>
  <div>
    <Header :name="$t('auth.photo_upload.sub_heading')"/>

    <div class="container pt-5 pb-5">
      <div class="flex items-center justify-between mb-5">
        <h1>
          {{ $t('auth.photo_upload.heading') }}
        </h1>

        <router-link
          class="text-gray-500"
          :to="urlToRedirect"
          @click="openSurvey">
          {{ $t('common.skip') }}
        </router-link>
      </div>

      <ThePhotoUpload/>

      <p class="text-caption text-center text-gray-500 mt-5">
        <VRuntimeTemplate
          :template="$t('auth.photo_upload.terms_agreement', { terms_of_service: termsOfService, privacy_policy: privacyPolicy })"/>
      </p>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Functional/Header'
import ThePhotoUpload from '@/components/PhotoUpload/ThePhotoUpload'
import { mapWritableState, mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { useQuizStore } from '@/stores/quiz'
import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

export default {
  name: 'PhotoUpload',
  components: {
    Header,
    ThePhotoUpload
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useQuizStore, [
      'survey',
      'quizAllowed'
    ]),
    ...mapWritableState(useQuizStore, [
      'surveyOpen',
      'surveyCause'
    ]),
    ...mapWritableState(useAuthStore, [
      'pathToRedirect',
      'landingPath'
    ]),
    urlToRedirect () {
      if (this.$route.query?.r === 'me') {
        return '/me'
      } else if (this.landingPath?.name === 'Creator' || this.landingPath?.name === 'Post') {
        return this.landingPath?.path
      } else if (this.pathToRedirect) {
        return this.pathToRedirect
      } else {
        return '/'
      }
    },
    termsOfService () {
      return `<a
                href="${window.one2fan.origin}/terms"
                target="_blank"
                class="text-primary">
                {{ $t("common.terms_of_service") }}
              </a>`
    },
    privacyPolicy () {
      return `<a
                href="${window.one2fan.origin}/policy"
                target="_blank"
                class="text-primary">
                {{ $t('common.privacy_policy') }}
              </a>`
    }
  },
  mounted () {
    clearQueriesAndAddWebviewParams()
  },
  methods: {
    openSurvey () {
      if (this.survey.showAfterSignup && this.quizAllowed) {
        setTimeout(() => {
          this.surveyCause = 'after_signup'
          this.surveyOpen = true
        }, 300)
      }
    }
  }
}
</script>

