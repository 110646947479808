<template>
  <div>
    <Sidebar
      ref="sidebar"
      v-touch:swipe.right="swipeHandler">
      <template #sidebarContent="{ toggleSidebar }">
        <SidebarContent
          :close-sidebar="toggleSidebar"
          @open-lang-switcher="$refs.langSwitcher.openModal()"/>
      </template>

      <template #default="{ toggleSidebar }">
        <main class="max-w-md mx-auto full-height w-full relative">
          <div class="full-height pb-11">
            <a
              v-if="!isDatingEnabled"
              class="absolute top-3 left-3 z-40 cursor-pointer"
              @click="toggleSidebar">
              <InlineSvg
                :src="barsSVG"
                class="mb-1.5 w-5 text-white"/>
            </a>

            <TheFeed type="public"/>
          </div>

          <SwiperPreview
            v-if="publicFeed.length >= 1 && showSwiperPreview && isMobile"
            :image="swiperPreviewImage"/>
        </main>
      </template>
    </Sidebar>

    <Navbar/>

    <Modal
      v-slot="{ closeModal }"
      ref="signUpGiftForm"
      name="SignUpGiftForm"
      :has-indents="false"
      rounded-class="rounded-t-2.5xl"
      position="bottom">
      <SignUpGiftForm @close="closeModal()"/>
    </Modal>

    <Modal
      v-slot="{ closeModal }"
      ref="langSwitcher"
      position="bottom"
      overflow-class="overflow-y-auto"
      rounded-class="rounded-t-2.5xl"
      :has-indents="false"
      name="LangSwitcher">
      <LangSwitcherContent @close="closeModal()"/>
    </Modal>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import TheFeed from '@/components/Feed/TheFeed'
import SignUpGiftForm from '@/components/SignUpGiftForm'
import Modal from '@/components/Functional/Modal'
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import { usePaymentStore } from '@/stores/payment'
import Sidebar from '@/components/Functional/Sidebar'
import barsSVG from '@/assets/svg/light/bars.svg'
import SidebarContent from '@/components/ModalContents/SidebarContent'
import SwiperPreview from '@/components/SwiperPreview'
import { useFeedStore } from '@/stores/feed'
import isMobile from 'ismobilejs'
import LangSwitcherContent from '@/components/ModalContents/LangSwitcherContent'
import { useAppStore } from '@/stores/app.js'
import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

export default {
  name: 'Home',
  components: {
    Navbar,
    TheFeed,
    SignUpGiftForm,
    Modal,
    Sidebar,
    SidebarContent,
    SwiperPreview,
    LangSwitcherContent
  },
  data () {
    return {
      barsSVG: barsSVG,
      isMobile: isMobile(window.navigator).any
    }
  },
  computed: {
    ...mapState(usePaymentStore, [
      'paymentProcessor'
    ]),
    ...mapState(useAuthStore, [
      'showGiftPopup',
      'showSwiperPreview',
      'isTokenExist',
      'webViewEscape',
      'isAuthenticated'
    ]),
    ...mapState(useFeedStore, [
      'publicFeed'
    ]),
    ...mapState(useAppStore, [
      'isDatingEnabled'
    ]),
    swiperPreviewImage () {
      if (this.publicFeed.length >= 1) {
        const post = this.publicFeed[1]

        return post?.type === 0 ? post.videoFirstFrameImageUrl : post?.imageUrls.r640x960
      }

      return ''
    }
  },
  beforeCreate () {
    document.title = 'One2fan – get closer to the creators'
  },
  mounted () {
    if (this.showGiftPopup && this.paymentProcessor === 'stripe') {
      setTimeout(this.$refs.signUpGiftForm.openModal, 1500)
    }

    this.invites.initInvites(this.$route)

    clearQueriesAndAddWebviewParams()
  },
  methods: {
    swipeHandler (event) {
      this.$refs.sidebar.toggleSidebar(event === 'right')
    }
  }
}
</script>
