<template>
  <Header
    :name="isDatingEnabled ? $t('user.me.premium_subscription') : $t('user.subscriptions.sub_heading')"
    class="mb-4"/>

  <div class="container">
    <PremiumSubscription v-if="isDatingEnabled"/>

    <TheSubscriptions v-else/>
  </div>
</template>

<script>

import Header from '@/components/Functional/Header'
import TheSubscriptions from '@/components/Subscriptions/TheSubscriptions'
import { mapState } from 'pinia'
import { useAppStore } from '@/stores/app'
import PremiumSubscription from '@/components/Subscriptions/PremiumSubscription'
import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

export default {
  name: 'Subscriptions',
  components: {
    Header,
    TheSubscriptions,
    PremiumSubscription
  },
  data () {
    return {}
  },
  computed: {
    ...mapState(useAppStore, [
      'isDatingEnabled'
    ])
  },
  mounted () {
    clearQueriesAndAddWebviewParams()
  },
  methods: {}
}
</script>

