<script setup>
  import { useAppStore } from '@/stores/app.js'

  function debug1 () {
    window.location.reload(true)
  }

  function debug2 () {
    window.history.replaceState(null, '', window.location.href)
  }

  function debug3 () {
    const appStore = useAppStore()

    const relativePath = window.location.pathname + window.location.search + window.location.hash
    appStore.router.replace(relativePath)
  }

  function debug4 () {
    window.location.assign(location.href)
  }

  function debug5 () {
    history.go(0)
  }

  function addHash1 () {
    window.location.hash = 'exampleHash'
  }
  function addHash2 () {
    const hash = 'exampleHash'
    window.location.href = `${window.location.origin}${window.location.pathname}#${hash}`
  }
  function addHash3 () {
    history.replaceState(null, null, '#exampleHash')
  }
</script>

<template>
  <div class="max-w-lg mx-auto py-10">
    <h2 class="mb-4">Reload buttons</h2>

    <div class="flex gap-2">
      <button type="button" @click="debug1">
        Debug 1
      </button>

      <button type="button" @click="debug2">
        Debug 2
      </button>

      <button type="button" @click="debug3">
        Debug 3
      </button>

      <button type="button" @click="debug4">
        Debug 4
      </button>

      <button type="button" @click="debug5">
        Debug 5
      </button>
    </div>
  </div>
</template>