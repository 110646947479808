<script setup>
  import Header from '@/components/Functional/Header'
  import TheDetails from '@/components/Details/TheDetails'
  import { useRoute } from 'vue-router'
  import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'
  import { onMounted } from 'vue'

  const route = useRoute()

  const skipLink = route.query.r === 'me' ? '/photo-upload?r=me' : '/photo-upload'

  onMounted(() => {
    clearQueriesAndAddWebviewParams()
  })
</script>

<template>
  <Header :name="$t('auth.details.about_me')"/>

  <div class="container pt-5 pb-5">
    <div class="flex items-center justify-between mb-5">
      <h1>
        {{ $t("auth.details.details") }}
      </h1>

      <router-link
        class="text-gray-500"
        :to="skipLink">
        {{ $t("common.skip") }}
      </router-link>
    </div>

    <TheDetails/>
  </div>
</template>

