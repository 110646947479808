<script setup>
  import { onMounted, onUnmounted } from 'vue'
  import { useAuthStore } from '@/stores/auth.js'
  import { useAppStore } from '@/stores/app.js'
  import app from '@/main'
  import dayjs from '@/plugins/dayjsPluginNext.js'
  import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'
  import { webviewType } from '@/helpers/is-webview.js'

  const authStore = useAuthStore()
  const appStore = useAppStore()

  let refreshInterval = null

  async function fetchAndSetSigninToken () {
    try {
      const { data } = await app.wsp.sendRequest({
        data: {},
        method: 'webViewEscape.refreshSigninToken'
      })

      if (data.signinToken?.value) {
        authStore.options.webViewEscape.signinToken = data.signinToken
        scheduleTokenRefresh(data.signinToken.nextRefreshTs)

        clearQueriesAndAddWebviewParams()

        // Reload if TikTok webview on iOS
        const wvType = webviewType()

        if ((wvType === 'tiktok' || appStore.webViewSimulate) && appStore.isiOS) {
          window.location.reload()
        }
      }
    } catch (error) {
      console.error('Error fetching signin token:', error)
    }
  }

  function scheduleTokenRefresh (nextRefreshTs) {
    const nextRefreshTime = dayjs.utc(nextRefreshTs * 1000)
    const currentTime = dayjs.utc()
    const delay = nextRefreshTime.diff(currentTime)

    if (delay > 0) {
      if (refreshInterval) {
        clearTimeout(refreshInterval)
      }

      refreshInterval = setTimeout(() => {
        fetchAndSetSigninToken()
      }, delay)
    }
  }

  onMounted(() => {
    if (authStore.webViewEscape.signinToken?.nextRefreshTs) {
      scheduleTokenRefresh(authStore.webViewEscape.signinToken.nextRefreshTs)
    }
  })

  onUnmounted(() => {
    if (refreshInterval) {
      clearTimeout(refreshInterval)
    }
  })
</script>

<template>
  <div/>
  <!--<pre class="fixed top-0 left-0 bg-red-50" @click="fetchAndSetSigninToken">{{ authStore.options.webViewEscape.signinToken.value }}</pre>-->
</template>
