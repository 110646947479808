<script setup>
  import { ref, onMounted, onUnmounted, provide } from 'vue'
  import { onCodeDown, key_Escape } from 'on-hotkey'

  const props = defineProps({
    wrapperClass: {
      type: String,
      default: ''
    },
    onDestroy: {
      type: Function,
      required: true
    },
    allowCloseByClickOutside: {
      type: Boolean,
      default: true
    },
    ariaLabel: {
      type: String,
      default: ''
    }
  })

  const isOpen = ref(false)

  onMounted(() => {
    isOpen.value = true
  })

  async function close (overlay) {
    if (overlay) {
      if (!props.allowCloseByClickOutside) {
        return
      }
    }

    isOpen.value = false

    await new Promise(resolve => setTimeout(resolve, 10))

    setTimeout(() => {
      props.onDestroy()
    }, 200)
  }

  provide('close', close)

  const unsubscribeEscapeKey = onCodeDown(key_Escape, () => {
    close()
  })

  onUnmounted(() => {
    unsubscribeEscapeKey()
  })

  defineExpose({
    isOpen
  })
</script>

<template>
  <div
    class="relative z-30"
    :aria-labelledby="ariaLabel"
    role="dialog"
    aria-modal="true"
    @click="close(true)">
    <!-- Overlay -->
    <transition name="fade">
      <div
        v-if="isOpen"
        class="fixed inset-0 bg-black bg-opacity-70"/>
    </transition>

    <!-- Modal content -->
    <transition name="fade-appear">
      <div
        v-show="isOpen"
        class="fixed inset-0 z-40 w-screen overflow-y-scroll">
        <div
          :class="[wrapperClass]"
          class="flex min-h-full justify-center items-center relative">
          <slot/>
        </div>
      </div>
    </transition>
  </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.2s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .fade-appear-enter-active {
    transition: opacity 0.2s ease;
  }

  .fade-appear-enter-from {
    opacity: 0;
  }
</style>