<script setup>
  const starStruckPng = require('@/assets/images/emoji/star-struck.png')

  import ModalWindow from '@/components/Functional/ModalWindow.vue'
  import OpenInBrowserModal from '@/components/ModalsNext/OpenInBrowserModal.vue'
  import { createComponent } from '@/helpers/utils.js'

  function openInBrowserModal () {
    const modalProps = {
      wrapperClass: 'max-w-md mx-auto w-full h-full flex-col'
    }

    const OpenInBrowserModalProps = {}

    createComponent(ModalWindow, modalProps, OpenInBrowserModal, OpenInBrowserModalProps)
  }
</script>

<template>
  <div
    class="rounded-lg bg-gray-200 p-4 cursor-pointer"
    @click="openInBrowserModal">
    <div class="flex items-center gap-2">
      <img
        class="h-6 w-6"
        alt="Star-struck emoji"
        :src="starStruckPng">

      <span class="font-bold">
        {{ $t('external.browser.unlock_full') }}
      </span>
    </div>

    <ol class="list-decimal pl-6 text-[14px] leading-5 mt-2">
      <li>
        {{ $t('external.browser.point1') }}
      </li>

      <li>
        {{ $t('external.browser.point2') }}
      </li>
    </ol>
  </div>
</template>