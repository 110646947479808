<template>
  <Sidebar>
    <template #sidebarContent>
      <SidebarContent
        :close-sidebar="toggleSidebar"
        @open-lang-switcher="$refs.langSwitcher.openModal()"/>
    </template>

    <template #default="{ toggleSidebar }">
      <main class="max-w-md mx-auto full-height w-full relative">
        <div class="full-height pb-11">
          <a
            class="absolute top-3 left-3 z-40 cursor-pointer"
            @click="toggleSidebar">
            <InlineSvg
              :src="barsSVG"
              class="mb-1.5 w-5 text-white"/>
          </a>

          <TheFollowing v-if="isAuthenticated"/>
        </div>
      </main>
    </template>
  </Sidebar>

  <Modal
    v-slot="{ closeModal }"
    ref="langSwitcher"
    position="bottom"
    overflow-class="overflow-y-auto"
    rounded-class="rounded-t-2.5xl"
    :has-indents="false"
    name="LangSwitcher">
    <LangSwitcherContent @close="closeModal()"/>
  </Modal>

  <Navbar/>
</template>

<script>
import Navbar from '@/components/Navbar'
import TheFollowing from '@/components/Following/TheFollowing'
import { mapState } from 'pinia'
import { useAuthStore } from '@/stores/auth'
import Sidebar from '@/components/Functional/Sidebar'
import barsSVG from '@/assets/svg/light/bars.svg'
import SidebarContent from '@/components/ModalContents/SidebarContent'
import LangSwitcherContent from '@/components/ModalContents/LangSwitcherContent'
import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

export default {
  name: 'Following',
  components: {
    Navbar,
    TheFollowing,
    Sidebar,
    SidebarContent,
    LangSwitcherContent
  },
  data () {
    return {
      barsSVG: barsSVG
    }
  },
  computed: {
    ...mapState(useAuthStore, ['isAuthenticated'])
  },
  async mounted () {
    this.invites.initInvites(this.$route)

    clearQueriesAndAddWebviewParams()
  }
}
</script>
