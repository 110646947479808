<template>
  <div class="max-w-md mx-auto full-height pb-12">
    <PostPlaceholder v-if="isLoading"/>

    <template v-else>
      <ThePost v-if="initialPost?.id"/>
    </template>

    <Navbar/>
  </div>
</template>

<script>
import ThePost from '@/components/Post/ThePost'
import Navbar from '@/components/Navbar'
import { mapActions, mapState, mapWritableState } from 'pinia'
import { usePostStore } from '@/stores/post'
import { useCreatorStore } from '@/stores/creator'
import PostPlaceholder from '@/components/Post/PostPlaceholder'
import { useUserStore } from '@/stores/user'
import { usePaymentStore } from '@/stores/payment'
import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

export default {
  name: 'Post',
  components: {
    ThePost,
    Navbar,
    PostPlaceholder
  },
  data () {
    return {
      constantLoading: true,
      loading: true,
      isFirstVisit: true
    }
  },
  computed: {
    ...mapWritableState(useCreatorStore, [
      'creator'
    ]),
    ...mapState(usePostStore, [
      'initialPost'
    ]),
    ...mapState(useUserStore, [
      'balance'
    ]),
    isLoading () {
      return this.constantLoading || this.loading
    }
    // isEnoughMoney () {
    //   return this.balance >= this.thePost.price
    // }
  },
  async beforeMount () {
    const routeId = this.$route.params.postId

    setTimeout(() => {
      this.constantLoading = false
    }, 500)

    if (routeId) await this.postGet(routeId)

    if (!this.initialPost?.id) {
      await this.$router.push('/404')
      return
    }

    await this.userGet({
      id: this.initialPost.userId
    })

    if (this.initialPost.userId && this.creator.contact) {
      this.invites.initInvites(this.$route, this.initialPost.userId, this.creator.contact, this.creator)
    }

    this.loading = false
  },
  async mounted () {
    // Unlcok post with a link
    const query = this.$route.query
    const routeId = this.$route.params.postId

    if (query.unlock && routeId) {
      const { post } = await this.postGet(routeId)

      if (post) {
        const data = {
          userId: post.userId,
          number: post.number,
          cost: post.price
        }

        await this.postPurchase(data)

        const newQuery = { ...this.$route.query }
        delete newQuery.unlock
        this.$router.replace({ query: newQuery })
      }
    }

    clearQueriesAndAddWebviewParams()
  },
  beforeUnmount () {
    usePostStore().$reset()
  },
  methods: {
    ...mapActions(usePaymentStore, [
      'postPurchase'
    ]),
    ...mapActions(usePostStore, [
      'postGet'
    ]),
    ...mapActions(useCreatorStore, [
      'userGet'
    ])
  }
}
</script>

