<template>
  <Header :name="$t('user.me_edit.edit_profile')"/>

  <MeEditPlaceholder v-if="isLoading"/>

  <div
    v-else
    class="container mt-3">
    <MainPhotoUploader class="mb-4"/>

    <MePhotos class="mb-8"/>

    <MeForm/>
  </div>
</template>

<script>
  import Header from '@/components/Functional/Header'
  import MainPhotoUploader from '@/components/Me/MainPhotoUploader'
  import MePhotos from '@/components/Me/MePhotos'
  import MeForm from '@/components/Me/MeForm'
  import { useMeta } from 'vue-meta'
  import { mapState } from 'pinia'
  import { useUserStore } from '@/stores/user'
  import MeEditPlaceholder from '@/components/Me/MeEditPlaceholder'
  import { clearQueriesAndAddWebviewParams } from '@/helpers/app-helpers.js'

  export default {
    name: 'MeEdit',
    components: {
      Header,
      MainPhotoUploader,
      MePhotos,
      MeForm,
      MeEditPlaceholder
    },
    setup () {
      useMeta({
        title: 'My profile',
        og: {
          title: 'My profile',
          description: 'One2fan – get closer to the creators',
          site_name: 'Fancy',
          type: 'website'
        }
      })
    },
    data () {
      return {
        constantLoading: true
      }
    },
    computed: {
      ...mapState(useUserStore, [
        'user'
      ]),
      isLoading () {
        return !this.user.userId || this.constantLoading
      }
    },
    created () {
      setTimeout(() => {
        this.constantLoading = false
      }, 500)
    },
    mounted () {
      clearQueriesAndAddWebviewParams()
    },
    methods: {}
  }
</script>
